<script setup lang="ts">
    import type { Asset, AssetStyleSize } from '~/@types/cms';
    import type { SharedImageProps } from '~/components/atm/Image.vue';
    type HeightWidth = number | `${number}px` | undefined;

    // const videoRegex = new RegExp(/^(yt)[:]{1}.*/);
    export interface AssetProps extends SharedImageProps {
        styleSize?: AssetStyleSize;
        videoPreview?: Asset;
        showCaption?: boolean;
        isFullbleed?: boolean;
        // TODO: unused / not implemented
        // aspect?: number | undefined;
        // lazy?: boolean; // Lazy load asset when in viewport
        // videoTitle: any; // Custom preview title for a video
        // hoverData: any; // Asset to be displayed when a user hover's over the component.
        // persistObserver?: boolean; // Persistently observe the image - currently mainly used in conjunction with the `lazy` prop and WGallery
        // cssHeight?: string;  // default: '100%', Explicitly set the height in the css options of the assets
        // color?: string ; // default: 'FFFFFF', Background color for transparent images
        // modal?: boolean; // Open video in a modal, if false video will be played inline.
        // autoplay?: boolean; // Video will auto play if set to `true`
        // clickable?: boolean; // Style component as interactive/clickable
        // disableImageBackgroundColor?: boolean; // Disable background color for an image, for example for transparent logos
    }

    const props = withDefaults(defineProps<AssetProps>(), {
        showCaption: true,
        isFullbleed: false,
    });

    const maybeDataObject = computed(() => {
        return typeof props.data === 'object' ? props.data : null;
    });

    const type = computed(() => {
        if (!maybeDataObject.value) return 'image';
        if (maybeDataObject.value?.id?.startsWith('video:')) {
            return 'video';
        }
        if (maybeDataObject.value?.id?.startsWith('yt:')) {
            return 'youtube';
        }
        return 'image';
    });

    const caption = computed(() => {
        return maybeDataObject.value?.caption;
    });

    const showInlineCaption = computed(() => {
        return caption.value && maybeDataObject.value?.captionDisplay === 'display';
    });

    const copyright = computed(() => {
        return maybeDataObject.value?.copyright;
    });
</script>
<template>
    <component
        :is="type === 'image' && showInlineCaption ? 'figure' : 'div'"
        class="atm-asset">
        <lazy-atm-image
            v-if="(src || data) && type === 'image'"
            :src="src"
            :data="data"
            :width="width"
            :height="height"
            :no-transform="noTransform"
            :render-as="renderAs"
            :cover="cover"
            :sizes="sizes"
            :alt="caption && !showInlineCaption ? caption : undefined"
            :image-preload="imagePreload"
            :image-loading="imageLoading" />
        <lazy-atm-background-video
            v-else-if="(src || data) && type === 'video'"
            :src="src"
            :data="data" />
        <lazy-atm-video
            v-else-if="type === 'youtube'"
            :data="data"
            :style-size="styleSize"
            :preview-image="videoPreview" />

        <lazy-atm-caption
            v-if="type === 'image' && showInlineCaption"
            :class="['opacity-100 transition-opacity', { 'opacity-0': !showCaption }]"
            :caption="caption"
            :copyright="copyright"
            :isFullbleed="isFullbleed"
            type="figcaption" />
    </component>
</template>
